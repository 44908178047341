








































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ForgotPasswordSms extends Vue {
  errorMsg = "";
  sms = "";
  loading = false;
  async next(): Promise<void> {
    this.loading = true;
    try {
      await this.$store.dispatch("forgotPassword/setSMSCode", this.sms);
      this.$router.push({ name: "forgot-password-password" });
    } catch (error) {
      if (error === "wrong_code") {
        this.errorMsg = this.$t(
          "ChangePassword_InputCode_Input_Error"
        ) as string;
      } else {
        this.errorMsg = this.$t("SignUp_SMSCode_Verification_Error") as string;
      }
    }
    this.loading = false;
  }

  async resendSMS(): Promise<void> {
    if (this.allowResend) {
      await this.$store.dispatch("forgotPassword/resendSMS");
    }
  }

  get allowResend(): boolean {
    return this.$store.state.forgotPassword.allowResendSMS;
  }

  mounted(): void {
    if (this.$store.state.register.smsCode) {
      this.sms = this.$store.state.register.smsCode;
    }
  }
}
